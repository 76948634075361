<template>
    <div  @click=" $emit('selectBuilder', item.id ) " :class=" selected ? 'h-100' : '' ">
        <button class="btn btn-light w-100 mb-1" :class=" leftMenu ? '' : 'd-none' ">
            <span :class=" selected ? 'text-info': '' ">
                <i class="fa fa-image mr-2"></i>
                <span v-text="item.type"></span>
            </span>
        </button>
        <div class="animated fadeIn d-flex flex-column justify-content-between h-100" v-if="selected"
            :class=" leftMenu ? 'left-menu' : 'right-menu' ">
            <div>
                <h6 class="page-title" v-text="item.type"></h6>
                <div class="panel">
                    <div class="form-group">
                        <label for="">Image</label>
                        <button class="btn btn-light form-control" @click=" $refs.imageInput.click()">Select
                            image</button>
                        <input type="file" accept="image/*" class="form-control" hidden @change="fnChangeImage"
                            ref="imageInput">
                    </div>
                    <div class="form-group">
                        <label for="">Height</label>
                        <input type="number" min="50" max="300" v-model="item.height" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="">Type</label>
                        <select name="" id="" v-model="item.bannerType" class="custom-select">
                            <option value="contain">Contain</option>
                            <option value="cover">Cover</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="">Background Color</label>
                        <select name="" id="" v-model="item.bgColor" class="custom-select">
                            <option value="#000">Black</option>
                            <option value="#fff">White</option>
                        </select>
                    </div>
                    <!-- <div class="form-group">
                    <label for="">Repeat</label>
                    <select name="" id="" v-model="item.repeat" class="custom-select">
                        <option value="no-repeat">No repeat</option>
                        <option value="repeat">Cover</option>
                    </select>
                </div> -->
                </div>
            </div>
            <div class="panel">
                <button class="btn btn-light w-100" @click=" $emit('delete') ">
                    <i class="far fa-trash-alt mr-2"></i>
                    <span v-text=" 'Delete' "  ></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
            },
            selected: {
                type: Boolean,
            },
            leftMenu: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            async fnChangeImage(event) {
                // console.log(this.item.image);
                let newImage = await new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.onloadend = ((event) => {
                        resolve(event.target.result);
                    });
                    reader.readAsDataURL(event.target.files[0]);
                });
                this.item.image = newImage;
            },
        }
    }
</script>

<style lang="scss">
    .left-menu {
        display: none !important;

        @media only screen and (min-width: 767px) {
            display: block;
        }
    }

    // .right-menu {
    // @media only screen and (max-width: 767px) {
    //     display: none !important;
    // }
    // }
    .page-title {
        padding-left: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: 600;
        margin: 0;
    }

    .panel {
        padding: 0.5rem 1rem;
    }
</style>